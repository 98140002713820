import './App.css';
import { Routes, Route, useLocation } from 'react-router-dom';
import LanderPage from './freeAccess/LanderPage'
import RegisterPage from './authFlow/RegisterPage'
import LoginPage from './authFlow/LoginPage'
import DashBoard from './AuthAccess/userFlow/Dashboard';
import ReviewPage from './AuthAccess/ReviewerFlow/ReviewPage';
import SingleUserReview from './AuthAccess/ReviewerFlow/SingleUserReview'
import SinglePostedReview from './AuthAccess/ReviewerFlow/SinglePostedReview';
import NoMatch from './NoMatch'
import PostReviewPage from './AuthAccess/ReviewerFlow/PostReviewPage';
import { PreventAuthFlow, ProtectRoute } from './manageRoutes/ProtectedRoutes';
import AOS from 'aos'
import { useEffect, useLayoutEffect, useState } from 'react';
import AdvAccountDashboard from './AuthAccess/advancedAccount/Dashboard';
import ManageProjects from './AuthAccess/advancedAccount/ManageProjects';
import SingleProject from './AuthAccess/advancedAccount/SingleProject';
import UserViewProjectTeam from './AuthAccess/userFlow/UserViewProjectTeam';
import TeamRequested from './AuthAccess/userFlow/TeamsRequested';
import MyProfile from './AuthAccess/userFlow/MyProfile';
import TeamJoined from './AuthAccess/userFlow/TeamJoined';
import ProjectTasks from './AuthAccess/advancedAccount/ProjectTasks'
import ReviewerVerification from './AuthAccess/ReviewerFlow/ReviewerVerification';
import BlogLists from './freeAccess/BlogLists';
import SiteUnderDev from './freeAccess/SiteUnderDev';
import HowItWorks from './freeAccess/HowItWorks';
import SinglelBlog from './freeAccess/SingleBlog';
import PublicDisplayRankList from './freeAccess/PublicDisplayRankList';
import UserProjectTasks from './AuthAccess/userFlow/ProjectTasks';
import DisplayResults from './AuthAccess/advancedAccount/DisplayResults';
import UserSingleProject from './AuthAccess/userFlow/UserSingleProject';
import PublicProjectDisplay from './freeAccess/PublicProjectDisplay';
import PublicDisplaySingleProject from './freeAccess/PublicDisplaySingleProject';
import ManageTeam from './AuthAccess/advancedAccount/ManageTeam'
import AboutUs from './freeAccess/About';
import Students from './freeAccess/Students';
import Freelancers from './freeAccess/Freelancers';
import ProjectListers from './freeAccess/ProjectListers';
import ContactUs from './freeAccess/ContactUs';
import CollaborativeEditor from './AuthAccess/editors/CollaborativeEditor';
import TeamRemoved from './AuthAccess/userFlow/TeamRemoved';
import AuthComponent from './authFlow/AuthComponent';
import UpdateProfile from './AuthAccess/userFlow/UpdateProfile';
import WalletAndTranscations from './AuthAccess/userFlow/WalletAndTransactions';
import { useAuthStore } from './store/store';
import SiteUnderMaintenance from './SiteUnderMaintenance';
import CLIPage from './freeAccess/CLIPage';
import SinglePublicProfie from './freeAccess/SinglePublicProfie';
import DisplayPublicProjects from './AuthAccess/userFlow/DisplayProjects';
import TeamApplied from './AuthAccess/userFlow/TeamApplied';
import TermsAndConditions from './freeAccess/TermsAndConditions';
import PrivacyPolicy from './freeAccess/PrivacyPolicy';
import { Helmet, HelmetProvider } from "react-helmet-async";
import DiscussionList from './AuthAccess/discussions/DiscussionList';
import SingleDiscussion from './AuthAccess/discussions/SingleDiscussion';
import AskDevSemble from './freeAccess/AskDevSemble';
import UserTour from './freeAccess/UserTour';
import CommunityForm from './AuthAccess/community/CommunityForm';
import SembleForm from './AuthAccess/community/SembleForm';
import CommunitiesPage from './AuthAccess/community/CommunitiesPage';
import SemblesPage from './AuthAccess/community/SemblesPage';
import SingleSemble from './AuthAccess/community/SingleSamble';

const App = () => {
  useEffect(() => {
    AOS.init({
      duration: 1000
    })
  }, [])

  useEffect(() => {
    window.history.scrollRestoration = 'manual';
  }, []);

  const location = useLocation()

  const checkTokenExpiration = useAuthStore((state) => state.checkTokenExpiration);
  const setUserFromLocalStorage = useAuthStore((state) => state.setUserFromLocalStorage);

  useEffect(() => {
    setUserFromLocalStorage();

    const scheduleTokenCheck = () => {
      const user = JSON.parse(localStorage.getItem('user'));
      if (user?.token) {
        const token = user.token;
        const decoded = JSON.parse(atob(token.split('.')[1]));
        const expirationTime = decoded.exp * 1000;
        const currentTime = Date.now();
        const remainingTime = expirationTime - currentTime;

        if (remainingTime <= 0) {
          checkTokenExpiration();
        } else {
          setTimeout(() => {
            checkTokenExpiration();
          }, remainingTime);
        }
      }
    };

    scheduleTokenCheck();

  }, [checkTokenExpiration, setUserFromLocalStorage]);

  useLayoutEffect(() => {
    document.documentElement.scrollTo({ top: 0, left: 0, behavior: "instant" });
  }, [location.pathname])

  return (
    <HelmetProvider>
      <Routes>
        <Route index element={<LanderPage />} />
        <Route path="auth" element={<PreventAuthFlow><AuthComponent /></PreventAuthFlow>} />
        <Route path="ask-devsemble" element={<AskDevSemble />} />
        <Route path="user-tour" element={<UserTour />} />
        <Route path="communities" element={<CommunitiesPage />} />
        <Route path="community/:communitySlug" element={<SemblesPage />} >
          <Route path='semble/:assemblySlug' element={<SingleSemble />} />
        </Route>
        <Route path="site-under-maintenance" element={<SiteUnderMaintenance />} />
        <Route path='discussions' element={<DiscussionList />} />
        <Route path='discussion/:slug' element={<SingleDiscussion />} />
        <Route path='devs-cli' element={<CLIPage />} />
        <Route path='about-us' element={<AboutUs />} />
        <Route path='students' element={<Students />} />
        <Route path='freelancers' element={<Freelancers />} />
        <Route path='project-listers' element={<ProjectListers />} />
        <Route path='contact-us' element={<ContactUs />} />
        <Route path='blogs' element={<BlogLists />} />
        <Route path='single-blog/:blogId' element={<SinglelBlog />} />
        <Route path='public-display-rank-list' element={<PublicDisplayRankList />} />
        <Route path='public-display-rank-list/:rankId' element={<SinglePublicProfie />} />
        <Route path='under-development' element={<SiteUnderDev />} />
        <Route path='how-it-works' element={<HowItWorks />} />
        <Route path='terms-and-conditions' element={<TermsAndConditions />} />
        <Route path='privacy-policy' element={<PrivacyPolicy />} />
        <Route path='display-public-project' element={<PublicProjectDisplay />} />
        <Route path='display-single-public-project/:pId' element={<PublicDisplaySingleProject />} />
        {/* <Route path='login' element={<PreventAuthFlow><LoginPage /></PreventAuthFlow>} /> */}
        <Route path='register' element={<PreventAuthFlow><RegisterPage /></PreventAuthFlow>} />
        <Route path='reviewer-verification' element={<ProtectRoute><ReviewerVerification /></ProtectRoute>} />
        <Route path='dashboard' element={<ProtectRoute><DashBoard /></ProtectRoute>} >
          <Route path='Sembles' element={<ProtectRoute><CommunityForm /></ProtectRoute>} />
          <Route path='Sembles/:communityId' element={<ProtectRoute><SembleForm /></ProtectRoute>} />
          <Route path='team-requested' element={<ProtectRoute><TeamRequested /></ProtectRoute>} />
          <Route path='teams-joined' element={<ProtectRoute><TeamJoined /></ProtectRoute>} />
          <Route path='team-applied' element={<ProtectRoute><TeamApplied /></ProtectRoute>} />
          <Route path='teams-removed' element={<ProtectRoute><TeamRemoved /></ProtectRoute>} />
          <Route path='display-public-projects' element={<ProtectRoute><DisplayPublicProjects /></ProtectRoute>} />
          <Route path='my-profile' element={<ProtectRoute><MyProfile /></ProtectRoute>} />
          <Route path='wallet-and-transactions' element={<ProtectRoute><WalletAndTranscations /></ProtectRoute>} />
        </Route>
        <Route path='dashboard/update-profile' element={<ProtectRoute><UpdateProfile /></ProtectRoute>} />
        <Route path="dashboard/teams-joined/single-project/:pId" element={<ProtectRoute><UserSingleProject /></ProtectRoute>} >
          <Route path="project-tasks" element={<ProtectRoute><UserProjectTasks /></ProtectRoute>} />
        </Route>
        <Route path="dashboard/teams-joined/view-single-project-team/:pId" element={<ProtectRoute><UserViewProjectTeam /></ProtectRoute>} />
        <Route path="review-lists" element={<ProtectRoute><ReviewPage /></ProtectRoute>} >
          <Route path=":rId" element={<ProtectRoute><SingleUserReview /></ProtectRoute>} />
        </Route>
        <Route path="post-review-lists" element={<ProtectRoute><PostReviewPage /></ProtectRoute>} >
          <Route path=":rId" element={<ProtectRoute><SinglePostedReview /></ProtectRoute>} />
        </Route>
        <Route path="advanced-dashboard" element={<ProtectRoute><AdvAccountDashboard /></ProtectRoute>} />
        <Route path="advanced-dashboard/manage-projects" element={<ProtectRoute><ManageProjects /></ProtectRoute>} />
        <Route path="advanced-dashboard/display-results" element={<ProtectRoute><DisplayResults /></ProtectRoute>} />
        <Route path="advanced-dashboard/manage-projects/single-project/:pId" element={<ProtectRoute><SingleProject /></ProtectRoute>} >
          <Route path="project-tasks" element={<ProtectRoute><ProjectTasks /></ProtectRoute>} />
        </Route>
        <Route path="advanced-dashboard/manage-projects/manage-single-project/:pId" element={<ProtectRoute><ManageTeam /></ProtectRoute>} />
        <Route path="advanced-dashboard/manage-projects/manage-single-project/project-code-editor/:pId" element={<ProtectRoute><CollaborativeEditor /></ProtectRoute>} />
        <Route path="dashboard/teams-joined/single-project/project-code-editor/:pId" element={<ProtectRoute><CollaborativeEditor /></ProtectRoute>} />
        <Route path="*" element={<NoMatch />} />
      </Routes>
    </HelmetProvider>
  );
}

export default App;